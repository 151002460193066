import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable()
export class ToastService {
    toast: HTMLIonToastElement;

    constructor(private toastCtrl: ToastController) {}

    async setToast(message: string, cssClass = '', duration = 3000, position : 'middle' | 'top' | 'bottom' = 'middle') {
        // Si un Toast existe, on le supprime
        if (this.toast) {
            await this.toast.dismiss();
        }
        this.toast = await this.toastCtrl.create({
            message: message,
            duration: duration,
            position: position,
            cssClass: cssClass
        });
        await this.toast.present();
    }
}
