import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { MomentModule } from "ngx-moment";
import { HeaderPageModule } from "../header/header.module";
import { DerniersScansPage } from "./derniers-scans";
import { DerniersScansPageRoutingModule } from "./derniers-scans-routing.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule.forRoot(),
        DerniersScansPageRoutingModule,
        HeaderPageModule,
        MomentModule
    ],
    declarations: [DerniersScansPage]
})
export class DerniersScansPageModule {}