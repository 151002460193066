import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GoogleAnalytics } from '@awesome-cordova-plugins/google-analytics/ngx';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'component-search',
  templateUrl: 'recherche.html'
})
export class RecherchePage implements OnInit {
    recherche: any = '';
    @Output() search: EventEmitter<any> = new EventEmitter();

    constructor(
        private platform: Platform,
        private ga: GoogleAnalytics
    ) {}

    ngOnInit() {}
    
    onSubmit(): any {
        this.platform.ready().then(_ => {
            this.ga.trackEvent("Recherche", "submit", this.recherche);
        });
        this.search.emit(this.recherche);
    }
}