import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MenuPage } from './menu';
import { MomentModule } from 'ngx-moment';

import { MenuPageRoutingModule } from './menu-routing.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule.forRoot(),
        MenuPageRoutingModule,
        MomentModule
    ],
    declarations: [MenuPage],
    exports: [MenuPage]
})
export class MenuPageModule {}
