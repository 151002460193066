import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { GoogleAnalytics } from '@awesome-cordova-plugins/google-analytics/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { MenuController, NavController, Platform } from '@ionic/angular';
import { AuthService } from '../services/auth.service';
import { ElementsService } from '../services/elements.service';
import { LastScansService } from '../services/lastScans.service';

@Component({
  selector: 'page-element-verifie',
  templateUrl: 'element-verifie.html'
})
export class ElementVerifiePage implements OnInit {
    loader: boolean = false;
    element: any = {};
    equipmentId: number;
    rapportId: number;
    listeElementsVerifies = [];
    num: number;
    token: any = {};
    
    constructor(
        private iab: InAppBrowser,
        private platform: Platform,
        private ga: GoogleAnalytics,
        private navCtrl: NavController,
        private menuCtrl: MenuController,
        private authService: AuthService,
        private elementsService: ElementsService,
        private lastScansService: LastScansService,
        private route: ActivatedRoute
    ) {
        this.platform.ready().then(() => {
            this.ga.trackView("Elément vérifié");
        });
    }

    ngOnInit(): any {
        this.init();
    }

    async init(): Promise<any> {
        this.route.queryParams.subscribe(async params => {
            this.equipmentId = params.equipmentId !== undefined ? params.equipmentId : 0;
            this.rapportId = params.rapportId !== undefined ? params.rapportId : 0;
            this.listeElementsVerifies = params.listeElementsVerifies !== undefined ? JSON.parse(params.listeElementsVerifies) : [];
            this.num = params.elementVerifie !== undefined ? params.elementVerifie : 0;

            if (this.equipmentId === 0 && this.rapportId === 0 && this.listeElementsVerifies.length === 0) {
                const liste = await this.lastScansService.getLastScans();
                const token = await this.authService.getToken();
                if (liste !== null) {
                    for (let i in liste) {
                        if (liste[i].user == token.user) {
                            this.element = liste[i].scans[0].data;
                            this.loader = false;
                        }
                    }
                }
            } else if (this.listeElementsVerifies == undefined) {
                this.getElement();
            } else {
                this.element = this.listeElementsVerifies[this.num];
                this.loader = false;
            }
        });
    }
    
    getElement(): any {
        this.elementsService.getElementVerify(this.equipmentId, this.rapportId).subscribe({
            next: element => {
                this.element = element;
                this.loader = false;
            },
            error: async error => {
                console.error(error);
                if (error.status == 401 && !this.authService.refreshCalled) {
                    this.authService.refreshCalled = true;
                    const refreshToken = await this.authService.getRefreshToken();
                    if (refreshToken == null) {
                        await this.authService.removeToken();
                        await this.authService.removeRefreshToken();
                        this.menuCtrl.enable(true, 'unauthenticated');
                        this.menuCtrl.enable(false, 'authenticated');
                        this.navCtrl.navigateRoot('home');
                    } else {
                        this.authService.refresh(refreshToken).subscribe(async (data: any) => {
                            const token = await this.authService.getToken()
                            await this.authService.setToken(token.user, data.access_token, data.statut);
                            await this.authService.setRefreshToken(data.refresh_token)
                            const navigationExtras: NavigationExtras = {
                                queryParams: {
                                    rapportId: this.rapportId
                                }
                            };
                            this.navCtrl.navigateRoot('element-verifie', navigationExtras);
                        });
                    }
                } else {
                    this.authService.refreshCalled = false;
                    this.navCtrl.navigateRoot('home');
                }
            }
        });
    }

    swipeEvent(e: any): any {
        if (this.listeElementsVerifies != undefined) {
            if (e.direction == 2) {
                if (this.listeElementsVerifies.length <= this.num + 1) {
                    this.num = 0;
                } else {
                    this.num++;
                }
                const navigationExtras: NavigationExtras = {
                    queryParams: { 
                        listeElementsVerifies: JSON.stringify(this.listeElementsVerifies), 
                        elementVerifie: this.num 
                    }
                };
                this.navCtrl.navigateForward('element-verifie', navigationExtras);
            }
            else if (e.direction == 4) {
                if (0 > this.num - 1) {
                    this.num = this.listeElementsVerifies.length - 1;
                } else {
                    this.num--;
                }
                const navigationExtras: NavigationExtras = {
                    queryParams: { 
                        listeElementsVerifies: JSON.stringify(this.listeElementsVerifies), 
                        elementVerifie: this.num 
                    }
                };
                this.navCtrl.navigateBack('element-verifie', navigationExtras);
            }
        }
    }

    goToListeObservations(equipementId: number, rapportId: number): any {
        const navigationExtras: NavigationExtras = {
            queryParams: { 
                equipementId: equipementId, 
                rapportId: rapportId
            }
        };
        this.navCtrl.navigateForward('liste-observations-verifies', navigationExtras);
    }

    goToPdf(element: any): any {
        this.iab.create(encodeURI(element.pdfURL), '_system', 'location=yes');
    }

    goToListeElementsVerifies(rapportId: number): any {
        const navigationExtras: NavigationExtras = {
            queryParams: { 
                rapportId: rapportId
            }
        };
        this.navCtrl.navigateForward('liste-elements-verifies', navigationExtras);
    }

    isEmptyObject(obj: any): any {
        return (obj && (Object.keys(obj).length === 0));
    }
}