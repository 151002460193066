import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { HeaderPageModule } from "../header/header.module";
import { MotDePasseOubliePage } from "./mot-de-passe-oublie";
import { MotDePasseOubliePageRoutingModule } from "./mot-de-passe-oublie-routing.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule.forRoot(),
        MotDePasseOubliePageRoutingModule,
        HeaderPageModule
    ],
    declarations: [MotDePasseOubliePage]
})
export class MotDePasseOubliePageModule {}