import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'component-header',
    templateUrl: 'header.html'
})
export class HeaderPage {
    @Input() menuTitle: string = "Blue Trust Exploitation";
    @Input() navStyle: string = "";
    @Input() pictoLeft: string = "";
    @Input() pictoRight: string = "";
    @Input() hasPictoLeft: boolean = true;
    @Input() hasSearch: boolean = false;
    @Input() nomElement: string = "";
    @Output() pictoLeftClick = new EventEmitter();
    @Output() pictoRightClick = new EventEmitter();
    @Output() getSearch = new EventEmitter();
    controller: any;

    constructor() { }
}
