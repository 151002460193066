import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';
import { GoogleAnalytics } from '@awesome-cordova-plugins/google-analytics/ngx';
import { IonDatetime, IonPopover, MenuController, NavController, Platform } from '@ionic/angular';

import { API } from '../../app/config';
import { AuthService } from '../services/auth.service';
import { ObservationsService } from '../services/observations.service';

@Component({
    selector: 'page-suivi-observation',
    templateUrl: 'suivi-observation.html'
})
export class SuiviObservationPage implements OnInit {
    @ViewChild('cout') cout;
    @ViewChild('temps') temps;
    @ViewChild('popoverDatetime') datetime: IonDatetime;
    @ViewChild('popover') popover: IonPopover;
    observation: any = {
        id: 0,
        rapportId: 0,
        rapportNum: '',
        dateTraitement: null,
        date: '',
        suivi: null,
        isTraite: 0,
        cout: null,
        state: null,
        temps: null,
        auteur: null,
        pathImage: '',
        listPhoto: [],
        listPhotoASupprimer: []
    }
    suivi: any = {
        rapportId: 0,
        observationId: 0
    };
    loader: boolean = true;
    isReadonly: boolean = false;

    constructor(
    public api: API, 
     private platform: Platform,
     private ga: GoogleAnalytics,
     private navCtrl: NavController,
     private menuCtrl: MenuController,
     private route: ActivatedRoute,
     private camera: Camera,
     private observationsService: ObservationsService,
     private authService: AuthService
    ) {
        this.platform.ready().then(_ => {
            this.ga.trackView('Suivi observations');
        });
    }

    ngOnInit() {
        this.init();
    }

    async init(): Promise<any> {
        this.route.queryParams.subscribe(async params => {
            this.suivi = params.details !== undefined ? JSON.parse(params.details) : { rapportId: 0, observationId: 0 };

            const token = await this.authService.getToken();
            if (token.statut === 'collaborateur') {
                this.isReadonly = true;
            }
            this.observationsService.getRapportObservation(this.suivi.rapportId, this.suivi.observationId).subscribe({
                next: data => {
                    if (data.auteur === null) {
                        this.observation.auteur = token.user;
                        const dateNow = new Date();
                        const month = (dateNow.getMonth() + 1 < 10) ? '0' + (dateNow.getMonth() + 1) : dateNow.getMonth() + 1;
                        const day = (dateNow.getDate() < 10) ? '0' + dateNow.getDate() : dateNow.getDate();
                        this.observation.date = dateNow.getFullYear() + '-' + month + '-' + day;
                    } else {
                        this.observation = data;
                        this.observation.state = data.isTraite + '';
                        const dateNow = new Date(data.dateTraitement);
                        const month = (dateNow.getMonth() + 1 < 10) ? '0' + (dateNow.getMonth() + 1) : dateNow.getMonth() + 1;
                        const day = (dateNow.getDate() < 10) ? '0' + dateNow.getDate() : dateNow.getDate();
                        this.observation.date = dateNow.getFullYear().toString().padStart(4, '0') + '-' + month + '-' + day;
                    }
                    if (token.statut === 'collaborateur') {
                        this.observation.suivi = data.suivi.replace(new RegExp('\r?\n','g'), '<br>');
                    }
                    this.loader = false;
                },
                error: async error => {
                    console.error(error);
                    if (this.authService.refreshCalled) {
                        this.authService.refreshCalled = false;
                        this.navCtrl.navigateRoot('home');
                    } else if (error.status === 401) {
                        this.authService.refreshCalled = true;
                        const refreshToken = await this.authService.getRefreshToken();
                        if (refreshToken === null) {
                            await this.authService.removeToken();
                            await this.authService.removeRefreshToken();
                            this.menuCtrl.enable(true, 'unauthenticated');
                            this.menuCtrl.enable(false, 'authenticated');
                            this.navCtrl.navigateRoot('home');
                        } else {
                            this.authService.refresh(refreshToken).subscribe(async (data: any) => {
                                const token = await this.authService.getToken()
                                await this.authService.setToken(token.user, data.access_token, data.statut);
                                await this.authService.setRefreshToken(data.refresh_token)
                                const navigationExtras: NavigationExtras = {
                                    queryParams: { 
                                        details: this.suivi
                                    }
                                };
                                this.navCtrl.navigateRoot('suivi-observation', navigationExtras);
                            });
                        }
                    }
                }
            });
        });
    }

    onKeyPress(e: any): any {
        if(e.keyCode === 13) {
            this.cout._elementRef.nativeElement.children[0].blur();
            this.temps._elementRef.nativeElement.children[0].blur();
        }
    }

    takePicture(e: any): any {
        const options: CameraOptions = {
            quality: 75,
            destinationType: this.camera.DestinationType.DATA_URL,
            encodingType: this.camera.EncodingType.JPEG,
            mediaType: this.camera.MediaType.PICTURE,
            sourceType: this.camera.PictureSourceType.CAMERA,
            correctOrientation: true,
            targetWidth: 640,
            targetHeight: 480
        };
        this.camera.getPicture(options).then((imageData: any) => {
            const base64Image = imageData.replace(' ', '+');
            const photo = {
                observationId: this.suivi.observationId,
                imgToBase64: base64Image
            };
            this.observation.listPhoto.push(photo);
        }, (error: any) => {
            console.error(error);
        });
    }

    removePicture(photo: any, item: any): any {
        if (photo.id === undefined) {
            this.observation.listPhoto.splice(item, 1);
        } else {
            this.observation.listPhotoASupprimer.push(photo);
            this.observation.listPhoto.splice(item, 1);
        }
    }

    submitForm(): any {
        this.observation.id = this.suivi.observationId;
        this.observation.isTraite = this.observation.state;
        this.observation.dateTraitement = this.observation.date;
        this.observation.rapportId = this.suivi.rapportId;
        const observation = Object.assign({}, this.observation);
        observation.cout = Number(observation.cout);
        observation.temps = Number(observation.temps);
        delete observation.state;
        delete observation.date;
        this.observationsService.setRapportObservation(observation).subscribe( (data: any) => {
            this.navCtrl.pop();
        });
    }

    goBack(): any {
        this.navCtrl.pop();
    }

    confirmDate() {
        this.datetime.confirm();
        this.popover.dismiss();
    }
      
    resetDate() {
        this.datetime.reset(this.observation.date);
        this.popover.dismiss();
    }

    getStateLabel(state: string) {
        switch(state) {
            case '1': return 'Traitée';
            case '-1': return 'En cours';
            case '0': return 'A traiter';
        }
    }
}