import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { HeaderPageModule } from "../header/header.module";
import { HomePage } from "./home";
import { HomePageRoutingModule } from "./home-routing.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule.forRoot(),
        HomePageRoutingModule,
        HeaderPageModule
    ],
    declarations: [HomePage]
})
export class HomePageModule {}