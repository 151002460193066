import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { HeaderPageModule } from "../header/header.module";
import { ListeObservationsRapportPage } from "./liste-observations-rapport";
import { ListeObservationsRapportPageRoutingModule } from "./liste-observations-rapport-routing.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule.forRoot(),
        ListeObservationsRapportPageRoutingModule,
        HeaderPageModule
    ],
    declarations: [ListeObservationsRapportPage]
})
export class ListeObservationsRapportPageModule {}