import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { GoogleAnalytics } from '@awesome-cordova-plugins/google-analytics/ngx';
import { MenuController, NavController, Platform } from '@ionic/angular';
import { AuthService } from '../services/auth.service';
import { ElementsService } from '../services/elements.service';
import { LastScansService } from '../services/lastScans.service';

@Component({
  selector: 'page-liste-elements-verifies',
  templateUrl: 'liste-elements-verifies.html'
})
export class ListeElementsVerifiesPage implements OnInit {
    liste = <any>[];
    res = <any>{};
    filtres = <any>{};
    scrollInfinite: boolean = true;
    loader: boolean = true;
    hasCritique: boolean = false;
    rapportId: number = 0;
    offset: number = 0;
    pageSize: number = 20;
    search: string = '';
    
    constructor(
        private platform: Platform,
        private ga: GoogleAnalytics,
        private menuCtrl: MenuController,
        private navCtrl: NavController,
        private route: ActivatedRoute,
        private elementsService: ElementsService,
        private authService: AuthService,
        private lastScansService: LastScansService
    ) {
        this.platform.ready().then(() => {
            this.ga.trackView("Liste éléments vérifiés");
        });
    }

    ngOnInit(): any {       
        this.route.queryParams.subscribe(params => {
            this.rapportId = params.rapportId !== undefined ? params.rapportId : 0;
            this.filtres = params.filtres !== undefined ? JSON.parse(params.filtres) : {};
        }); 
        
    }

    async ionViewWillEnter(): Promise<any> {
        const liste = await this.lastScansService.getLastScans();
        const token = await this.authService.getToken();
        if (liste !== null) {
            for (let i in liste) {
                if (liste[i].user == token.user) {
                    this.hasCritique = (liste[i].scans[0].data.hasObservationCritique == true || liste[i].scans[0].data.nombreObservationsCritiques > 0);
                    break;
                }
            }
        }
        // On teste si le paramètre filtres est vide
        if (Object.keys(this.filtres).length === 0) {
            this.getListe();
        } else {
            this.getFiltres();
        }
    }
    
    getListe(): any {
        this.scrollInfinite = true;
        this.loader = true;
        this.offset = 0;
        this.pageSize = 20;
        this.elementsService.getListElementsVerify(this.rapportId, this.offset, this.pageSize).subscribe({
            next: res => {
                this.res = res;
                this.liste = res.data;
                this.loader = false;
                if (res.paging.totalRecordCount <= this.offset + this.pageSize) {
                    this.scrollInfinite = false;
                }
            },
            error: error => {
                this.gestionErreur(error);
            }
        });
    }

    getFiltres(): any {
        this.scrollInfinite = true;
        this.loader = true;
        this.offset = 0;
        this.pageSize = 20;
        this.search = '';
        this.filtres._observations = (this.filtres._observations === '2') ? null : this.filtres._observations;
        this.elementsService.getFiltresElementsVerify(
            this.rapportId,
            this.offset,
            this.pageSize,
            this.filtres._level1,
            this.filtres._level2,
            this.filtres._level3,
            this.filtres._observations
        ).subscribe({
            next: res => {
                this.res = res;
                this.liste = res.data;
                this.loader = false;
                if (res.paging.totalRecordCount <= this.offset + this.pageSize) {
                    this.scrollInfinite = false;
                }
            },
            error: error => {
                this.gestionErreur(error);
            }
        });
    }

    getSearch(search: string): any {
        this.filtres = {};

        if (search !== '') {
            this.scrollInfinite = true;
            this.search = search;
            this.loader = true;
            this.offset = 0;
            this.pageSize = 20;
            this.elementsService.getSearchListElementsVerify(this.rapportId, this.search, this.offset, this.pageSize).subscribe({
                next: res => {
                    this.res = res;
                    this.liste = res.data;
                    this.loader = false;
                    
                    if (res.paging.totalRecordCount <= this.offset + this.pageSize) {
                        this.scrollInfinite = false;
                    }
                },
                error: error => {
                    this.gestionErreur(error);
                }
            });
        } else if (this.search !== '') {
            this.search = '';
            this.filtres = {};
            this.getListe();
        }
    }

    detailsElementVerifie(item: any): any {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                listeElementsVerifies: JSON.stringify(this.liste),
                elementVerifie: item
            }
        };
        this.navCtrl.navigateForward('element-verifie', navigationExtras);
    }

    goToFilter(): any {
        const navigationExtras: NavigationExtras = {
            queryParams: { 
                rapportId: this.rapportId,
                isObservation: false,
                hasFilterType: false,
                filtres: JSON.stringify(this.filtres)
            }
        };
        this.navCtrl.navigateForward('filtres', navigationExtras);
    }

    doInfinite(event):Promise<any> {
        this.offset += this.res.paging.pageSize;
        this.pageSize = (this.res.paging.totalRecordCount > this.offset + this.res.paging.pageSize) ? this.res.paging.pageSize : this.res.paging.totalRecordCount - this.offset;
        
        return new Promise(
            (resolve: any) => {
                if (this.res.paging.totalRecordCount > this.offset) {
                    if (this.filtres != undefined && Object.keys(this.filtres).length !== 0) {
                        this.elementsService.getFiltresElementsVerify(
                            this.rapportId,
                            this.offset,
                            this.pageSize,
                            this.filtres._level1,
                            this.filtres._level2,
                            this.filtres._level3,
                            this.filtres._observations
                        ).subscribe({
                            next: res => {
                                this.res = res;
                                Array.prototype.push.apply(this.liste, res.data);
                                resolve();
                            },
                            error: error => {
                                this.gestionErreur(error);
                            }
                        });
                    } else if (this.search != '') {
                        this.elementsService.getSearchListElementsVerify(this.rapportId, this.search, this.offset, this.pageSize).subscribe({
                            next: res => {
                                this.res = res;
                                Array.prototype.push.apply(this.liste, res.data);
                                resolve();
                            },
                            error: error => {
                                this.gestionErreur(error);
                            }
                        });
                    } else {
                        this.elementsService.getListElementsVerify(this.rapportId, this.offset, this.pageSize).subscribe({
                            next: res => {
                                this.res = res;
                                Array.prototype.push.apply(this.liste, res.data);
                                resolve();
                            },
                            error: error => {
                                this.gestionErreur(error);
                            }
                        });
                    }
                } else {
                    this.scrollInfinite = false;
                }
                event.target.complete();
            }
        );
    }

    async gestionErreur(error: any): Promise<any> {
        console.error(error);
        if (this.authService.refreshCalled) {
            this.authService.refreshCalled = false;
            this.navCtrl.navigateRoot('home');
        } else if (error.status === 401) {
            this.authService.refreshCalled = true;
            const refreshToken = await this.authService.getRefreshToken();
            if (refreshToken === null) {
                await this.authService.removeToken();
                await this.authService.removeRefreshToken();
                this.menuCtrl.enable(true, 'unauthenticated');
                this.menuCtrl.enable(false, 'authenticated');
                this.navCtrl.navigateRoot('home');
            } else {
                this.authService.refresh(refreshToken).subscribe(async (data: any) => {
                    const token = await this.authService.getToken();
                    await this.authService.setToken(token.user, data.access_token, data.statut);
                    await this.authService.setRefreshToken(data.refresh_token);
                    const navigationExtras: NavigationExtras = {
                        queryParams: {
                            rapportId: this.rapportId
                        }
                    };
                    this.navCtrl.navigateRoot('liste-elements-verifies', navigationExtras);
                });
            }
        }
    }
}