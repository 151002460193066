import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ListeElementsVerifiesPage } from "./liste-elements-verifies";

const routes: Routes = [
    { path: '', component: ListeElementsVerifiesPage }
];
  
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ListeElementsVerifiesPageRoutingModule {}