import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ElementVerifiePage } from "./element-verifie";

const routes: Routes = [
    { path: '', component: ElementVerifiePage }
];
  
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ElementVerifiePageRoutingModule {}