import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { GoogleAnalytics } from '@awesome-cordova-plugins/google-analytics/ngx';
import { NavController, Platform } from '@ionic/angular';

import { API } from '../../app/config';

@Component({
  selector: 'page-details-observation',
  templateUrl: 'details-observation.html'
})
export class DetailsObservationPage implements OnInit {
    details: any;
    listeObservations: Array<any> = [];
    num: number;

    constructor(
        public api: API, 
        private platform: Platform,
        private ga: GoogleAnalytics,
        private navCtrl: NavController,
        private route: ActivatedRoute
    ) {
        this.platform.ready().then(_ => {
            this.ga.trackView("Détails observations");
        });
    }

    ngOnInit(): any {
        this.route.queryParams.subscribe(params => {
            this.num = params.details !== undefined ? params.details : 0;
            this.listeObservations = params.listeObservations !== undefined ? JSON.parse(params.listeObservations) : [];

            if (this.num >= 0 && this.listeObservations.length > 0) {
                this.details = this.listeObservations[this.num];
            } else {
                this.navCtrl.navigateForward('home');
            }
        });
    }

    swipeEvent(e): any  {
        if (this.listeObservations.length > 0) {
            if (e.direction == 2) {
                this.num = (this.listeObservations.length <= this.num + 1) ? 0 : this.num++;
            } else if (e.direction == 4) {
                this.num =  (0 > this.num - 1) ? this.listeObservations.length - 1 : this.num--;
            }
            this.details = this.listeObservations[this.num];
        }
    }

    goBack(): any {
        this.navCtrl.pop();
    }

    goToPageFollow(): any {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                details: JSON.stringify(this.details)
            }
        };
        this.navCtrl.navigateForward('suivi-observation', navigationExtras);
    }
}
