import { Component } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { GoogleAnalytics } from '@awesome-cordova-plugins/google-analytics/ngx';

@Component({
  selector: 'page-savoir-plus',
  templateUrl: 'savoir-plus.html'
})
export class SavoirPlusPage {
    constructor(
        private platform: Platform,
        private navCtrl: NavController,
        private ga: GoogleAnalytics
    ) {
        this.platform.ready().then(_ => {
            this.ga.trackView("En savoir plus");
        }); 
    }

    goBack(): any {
        this.navCtrl.pop();
    }
}
