import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { HeaderPageModule } from "../header/header.module";
import { FiltresPage } from "./filtres";
import { FiltresPageRoutingModule } from "./filtres-routing.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule.forRoot(),
        FiltresPageRoutingModule,
        HeaderPageModule
    ],
    declarations: [FiltresPage]
})
export class FiltresPageModule {}