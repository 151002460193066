import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { HeaderPageModule } from "../header/header.module";
import { DetailsObservationPage } from "./details-observation";
import { DetailsObservationPageRoutingModule } from "./details-observation-routing.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule.forRoot(),
        DetailsObservationPageRoutingModule,
        HeaderPageModule
    ],
    declarations: [DetailsObservationPage]
})
export class DetailsObservationPageModule {}