import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BarcodeScanner } from "@awesome-cordova-plugins/barcode-scanner/ngx";
import { GoogleAnalytics } from "@awesome-cordova-plugins/google-analytics/ngx";
import { MenuController, Platform } from "@ionic/angular";
import { AuthService } from "../services/auth.service";
import { Events } from "../services/events.service";
import { LastScansService } from "../services/lastScans.service";
import { QrCodeService } from "../services/qrCode.service";

@Component({
    selector: 'component-menu',
    templateUrl: 'menu.html'
})
export class MenuPage {
    @Input() menuTitle: string = "Blue Trust Exploitation";
    @Input() hasSubMenu: boolean = true;
    @Input() isAuth: boolean = false;
    @Output() pageClicked: EventEmitter<any> = new EventEmitter();

    hasLastScans: boolean = false;
    isElementVerifie: boolean = false;
    loader: boolean = false;
    lastScans: any;
    isIos: boolean = false;
    private user: string = '';
    private qrCodeScan: string = '';

    constructor(
        private lastScansService: LastScansService,
        private authService: AuthService,
        private events: Events,
        private barcodeScanner: BarcodeScanner,
        private platform: Platform,
        private qrCodeService: QrCodeService,
        private ga: GoogleAnalytics,
        private menuCtrl: MenuController
    ) {
        this.platform.ready().then(_ => {
            this.isIos = this.platform.is('ios');
        });
        this.init();
    }

    async init() {
        const liste = await this.lastScansService.getLastScans();
        const token = await this.authService.getToken();
        this.user = (token !== null) ? token.user : '';
        if(liste !== null) {
            for(let i in liste) {                                
                if(liste[i].user == this.user) {
                    this.lastScans = liste[i].scans[0].data;
                    this.hasLastScans = true;
                    this.isElementVerifie = (liste[i].scans[0].qrCodeType == "Equipement");
                }
            }
        }
        this.lastScansService.listeScans.subscribe(async (liste: Array<any>) => {
            const token = await this.authService.getToken();
            for (let i in liste) {
                if (liste[i].user == token.user) {
                    this.lastScans = liste[i].scans[0].data;
                    this.hasLastScans = true;
                    this.isElementVerifie = (liste[i].scans[0].qrCodeType == "Equipement");
                    break;
                } else {
                    this.lastScans = [];
                    this.hasLastScans = false;
                    this.isElementVerifie = false;
                }
            }
        });
        this.events.subscribe( 
            'scanSansRapport:qrCode', (data: any) => {
                this.qrCodeScan = data;
            }
        );
    }

    scan(): any {
        this.barcodeScanner.scan().then(async (barcodeData: any) => {
            if (!barcodeData.cancelled) {
                this.platform.ready().then(_ => {
                    this.ga.trackEvent("Scan QRCode", "scan");
                });
                this.loader = true;
                this.qrCodeService.getScanQRCode(barcodeData.text).subscribe( async (scan: any) => {
                    const token = await this.authService.getToken();
                    if (token !== null) {
                        const user = token.user;
                        if (scan.data.perimetre !== undefined) {
                            await this.lastScansService.setLastScans(user, scan);
                            this.platform.ready().then(_ => {
                                if (barcodeData.text.match("socotec")) {
                                    this.ga.trackEvent("QRCode valide SOCOTEC", "scan");
                                } else {
                                    this.ga.trackEvent("QRCode valide Non SOCOTEC", "scan");
                                }
                            });
                            this.loader = false;
                            if (scan.qrCodeType === "Installation") {
                                this.goToPage('rapport', null);
                            } else {
                                this.goToPage('element-verifie', null);
                            }
                        } else {
                            const refreshToken = await this.authService.getRefreshToken();
                            if (refreshToken !== null) {
                                this.authService.refresh(refreshToken).subscribe( async (res: any) => {
                                    const newToken = await this.authService.getToken();
                                    await this.authService.setToken(newToken.user, res.access_token, res.statut);
                                    await this.authService.setRefreshToken(res.refresh_token);
                                    this.qrCodeService.getScanQRCode(barcodeData.text).subscribe(async (scan: any) => {
                                        const user = newToken.user;
                                        await this.lastScansService.setLastScans(user, scan);
                                        this.platform.ready().then(_ => {
                                            if (barcodeData.text.match("socotec")) {
                                                this.ga.trackEvent("QRCode valide SOCOTEC", "scan");
                                            } else {
                                                this.ga.trackEvent("QRCode valide Non SOCOTEC", "scan");
                                            }
                                        });
                                        this.loader = false;
                                        if (scan.qrCodeType === "Installation") {
                                            this.goToPage('rapport', null);
                                        } else {
                                            this.goToPage('element-verifie', null);
                                        }
                                    });
                                });
                            } else {
                                await this.authService.removeToken();
                                await this.authService.removeRefreshToken();
                                this.platform.ready().then(_ => {
                                    if (barcodeData.text.match("socotec")) {
                                        this.ga.trackEvent("QRCode valide SOCOTEC", "scan");
                                    } else {
                                        this.ga.trackEvent("QRCode valide Non SOCOTEC", "scan");
                                    }
                                });
                                this.loader = false;
                                this.goToPage('rapport-sans-acces', scan);
                            }
                        }
                    } else {
                        this.platform.ready().then(_ => {
                            if (barcodeData.text.match("socotec")) {
                                this.ga.trackEvent("QRCode valide SOCOTEC", "scan");
                            } else {
                                this.ga.trackEvent("QRCode valide Non SOCOTEC", "scan");
                            }
                        });
                        this.loader = false;
                        this.goToPage('rapport-sans-acces', scan);
                    }
                }),
                (error: any) => {
                    console.error(error);
                    this.menuCtrl.close();
                    this.loader = false;
                    this.platform.ready().then(_ => {
                        if (error.status === 403) {
                            this.ga.trackEvent("QRCode permission refusée", "scan");
                        } else if (error.status === 404) {
                            this.ga.trackEvent("QRCode invalide", "scan");
                        }
                    });
                }
            }
        }, (error: any) => {
            this.loader = false;
            console.error(error);
        });
    }

    goToPage(page: string, data?: any): any {        
        if (page === 'connexion' && this.qrCodeScan !== '') {
            data = { 
                qrCode: this.qrCodeScan 
            };
        }
        this.qrCodeScan = '';
        this.pageClicked.emit({ page, data });
    }
}