import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { HeaderPageModule } from "../header/header.module";
import { ListeObservationsVerifiesPage } from "./liste-observations-verifies";
import { ListeObservationsVerifiesPageRoutingModule } from "./liste-observations-verifies-routing.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule.forRoot(),
        ListeObservationsVerifiesPageRoutingModule,
        HeaderPageModule
    ],
    declarations: [ListeObservationsVerifiesPage]
})
export class ListeObservationsVerifiesPageModule {}