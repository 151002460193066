import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, NavParams } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MenuPageModule } from './menu/menu.module';
import { AuthService } from './services/auth.service';
import { ApiService } from './services/api.service';
import { ToastService } from './services/toast.service';
import { API } from './config';
import { HttpClientModule } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage-angular';
import { StorageService } from './services/storage.service';
import { ElementsService } from './services/elements.service';
import { LastScansService } from './services/lastScans.service';
import { ObservationsService } from './services/observations.service';
import { QrCodeService } from './services/qrCode.service';
import { RapportService } from './services/rapport.service';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { GoogleAnalytics } from '@awesome-cordova-plugins/google-analytics/ngx';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';


@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        MenuPageModule,
        HttpClientModule,
        IonicStorageModule.forRoot({
            name: '__mydb'
        }),
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        API,
        AuthService,
        ApiService,
        ToastService,
        StorageService,
        ElementsService,
        LastScansService,
        ObservationsService,
        QrCodeService,
        RapportService,
        NavParams,
        Keyboard,
        InAppBrowser,
        GoogleAnalytics,
        BarcodeScanner,
        Camera,
        StatusBar
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
