import { Component } from "@angular/core";
import { MenuController, NavController, Platform } from "@ionic/angular";
import { AuthService } from "../services/auth.service";
import { LastScansService } from "../services/lastScans.service";
import { QrCodeService } from "../services/qrCode.service";
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { GoogleAnalytics } from '@awesome-cordova-plugins/google-analytics/ngx';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'page-connexion',
  templateUrl: 'connexion.html'
})
export class ConnexionPage {
    auth: any = {
        _profil: false,
        _authenticationMode: 'client',
        _username: '',
        _password: '',
        _souvenir: true
    };
    isApp: boolean = true;
    loader: boolean = false;
    identifiantError: boolean = false;
    showFooter: boolean = true;
    qrCode: string = '';
    
    constructor( 
        private platform: Platform,
        private ga: GoogleAnalytics,
        private navCtrl: NavController,
        private menuCtrl: MenuController,
        private authService: AuthService,
        private qrCodeService: QrCodeService,
        private lastScansService: LastScansService,
        private keyboard: Keyboard,
        private route: ActivatedRoute
    ) {
        this.platform.ready().then(_ => {
            this.ga.trackView('Connexion');
            this.isApp = (this.platform.is('android') || this.platform.is('ios')) && !this.platform.is('mobileweb');
            if (this.isApp) {
                this.keyboard.onKeyboardShow().subscribe( _ => {
                    this.showFooter = false;
                });
                this.keyboard.onKeyboardHide().subscribe( _ => {
                    this.showFooter = true;
                });
            }
        });
        this.route.queryParams.subscribe(params => {
            this.qrCode = params.qrCode;
        });
    }

    login(): any {
        this.loader = true;
        this.auth._authenticationMode = (this.auth._profil) ? 'collaborateur' : 'client';
        this.authService.login(this.auth).subscribe({
            next: async newToken => {
                await this.authService.setToken(this.auth._username, newToken.access_token, this.auth._authenticationMode);
                if (this.isApp) {
                    this.keyboard.hide();
                }
                // Pour garder sa connexion ouverte
                if (this.auth._souvenir) {
                    await this.authService.setRefreshToken(newToken.refresh_token);
                    await this.lastScansService.emitListeScans();
                    this.menuCtrl.enable(false, 'unauthenticated');
                    this.menuCtrl.enable(true, 'authenticated');
                    // Si le QR Code n'existe pas
                    if(this.qrCode === undefined) {
                        this.navCtrl.navigateRoot('home');
                    } else {
                        this.qrCodeService.getScanQRCode(this.qrCode).subscribe({
                            next: async scan => {
                                await this.lastScansService.setLastScans(this.auth._username, scan);
                                if (scan.qrCodeType == 'Installation') {
                                    this.navCtrl.navigateRoot('rapport');
                                } else {
                                    this.navCtrl.navigateRoot('element-verifie');
                                }
                            },
                            error: error => {
                                console.error(error);
                                this.navCtrl.navigateRoot('home');
                            }
                        });
                    }
                } else {
                    await this.lastScansService.emitListeScans();
                    this.menuCtrl.enable(false, 'unauthenticated');
                    this.menuCtrl.enable(true, 'authenticated');   
                    if (this.qrCode == undefined) {
                        this.navCtrl.navigateRoot('home');
                    } else {
                        this.qrCodeService.getScanQRCode(this.qrCode).subscribe({
                            next: async scan => {
                                await this.lastScansService.setLastScans(this.auth._username, scan);
                                if (scan.qrCodeType === 'Installation') {
                                    this.navCtrl.navigateRoot('raport');
                                } else {
                                    this.navCtrl.navigateRoot('element-verifie');
                                }
                            },
                            error: error => {
                                console.error(error);
                                this.navCtrl.navigateRoot('home');
                            }
                        });
                    }
                }
            },
            error: error => {
                console.error(error);
                this.loader = false;
                if (this.isApp) {
                    this.keyboard.hide();
                }
            }
        });
    }

    mdpOublie(): any {
        this.navCtrl.navigateForward('mot-de-passe-oublie');
    }

    checkFocus(): any {
        if (this.isApp) {
            this.showFooter = false;
        }
    }

    checkBlur(): any {
        if (this.isApp) {
            this.showFooter = true;
        }
    }
}
