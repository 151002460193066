import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
    { path: 'savoir-plus', loadChildren: () => import('./savoir-plus/savoir-plus.module').then(m => m.SavoirPlusPageModule) },
    { path: 'connexion', loadChildren: () => import('./connexion/connexion.module').then(m => m.ConnexionPageModule) },
    { path: 'mot-de-passe-oublie', loadChildren: () => import('./mot-de-passe-oublie/mot-de-passe-oublie.module').then(m => m.MotDePasseOubliePageModule) },
    { path: 'rapport', loadChildren: () => import('./rapport/rapport.module').then(m => m.RapportPageModule) },
    { path: 'element-verifie', loadChildren: () => import('./element-verifie/element-verifie.module').then(m => m.ElementVerifiePageModule) },
    { path: 'liste-observations-rapport', loadChildren: () => import('./liste-observations-rapport/liste-observations-rapport.module').then(m => m.ListeObservationsRapportPageModule) },
    { path: 'filtres', loadChildren: () => import('./filtres/filtres.module').then(m => m.FiltresPageModule) },
    { path: 'derniers-scans', loadChildren: () => import('./derniers-scans/derniers-scans.module').then(m => m.DerniersScansPageModule) },
    { path: 'liste-elements-verifies', loadChildren: () => import('./liste-elements-verifies/liste-elements-verifies.module').then(m => m.ListeElementsVerifiesPageModule) },
    { path: 'liste-observations-verifies', loadChildren: () => import('./liste-observations-verifies/liste-observations-verifies.module').then(m => m.ListeObservationsVerifiesPageModule) },
    { path: 'rapport-sans-acces', loadChildren: () => import('./rapport-sans-acces/rapport-sans-acces.module').then(m => m.RapportSansAccesPageModule) },
    { path: 'details-observation', loadChildren: () => import('./details-observation/details-observation.module').then(m => m.DetailsObservationPageModule) },
    { path: 'suivi-observation', loadChildren: () => import('./suivi-observation/suivi-observation.module').then(m => m.SuiviObservationPageModule) },
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: '**', redirectTo: 'home' }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
