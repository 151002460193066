import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, from } from 'rxjs';
import { API } from '../config';

@Injectable()
export class ObservationsService {
    constructor(private api: API, private apiService: ApiService) {}

    getListObservationsRapport(rapportId: number, offset: number, pageSize: number): Observable<any> {
        return from(this.apiService.get(this.api.URLS.observationsURL +'/'+ rapportId +'/'+ offset +'/'+ pageSize +'/'));
    }

    getSearchObservationsRapport(rapportId: number, search: string, offset: number, pageSize: number): Observable<any> {
        return from(this.apiService.get(this.api.URLS.observationsURL +'/'+ rapportId +'/Search/'+ search +'/'+ offset +'/'+ pageSize +'/'));
    }
    
    getFiltresObservationsRapport(rapportId: number, offset: number, pageSize: number, niv1: string, niv2: string, niv3: string, istraite?: string): Observable<any> {
        return from(this.apiService.get(this.api.URLS.observationsURL +'/'+ rapportId +'/Pagination/'+ offset +'/'+ pageSize +'/Filtrer?niveau1='+ niv1 +'&niveau2='+ niv2 +'&niveau3='+ niv3 +'&isTraite='+ istraite || '2'));
    }
    
    getListObservationsVerifies(rapportId: number, equipementId: number, offset: number, pageSize: number): Observable<any> {
        return from(this.apiService.get(this.api.URLS.observationsURL +'/'+ rapportId +'/'+ equipementId +'/'+ offset +'/'+ pageSize +'/'));
    }

    getSearchObservationsVerifies(rapportId: number, equipementId: number, search: string, offset: number, pageSize: number): Observable<any> {
        return from(this.apiService.get(this.api.URLS.observationsURL +'/'+ rapportId +'/'+ equipementId +'/Search/'+ search +'/'+ offset +'/'+ pageSize +'/'));
    }

    getRapportObservation(rapportId: number, observationId: number): Observable<any> {
        return from(this.apiService.get(this.api.URLS.observationsURL +'/Get/'+ rapportId +'/'+ observationId));
    }

    setRapportObservation(observation: number): Observable<any> {
        return from(this.apiService.post(this.api.URLS.observationsURL +'/Update', JSON.stringify(observation), true));
    }
}
