import { Component, OnInit } from '@angular/core';
import { AlertController, MenuController, NavController, Platform } from '@ionic/angular';
import { AuthService } from '../services/auth.service';
import { LastScansService } from '../services/lastScans.service';
import { RapportService } from '../services/rapport.service';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { GoogleAnalytics } from '@awesome-cordova-plugins/google-analytics/ngx';

@Component({
    selector: 'page-rapport',
    templateUrl: 'rapport.html'
})
export class RapportPage implements OnInit {
    user: string = '';
    rapport: any = { rapportId: 0 };
    rapportId: number = 0;
    loader: boolean = true;

    constructor(
        private iab: InAppBrowser,
        private platform: Platform,
        private ga: GoogleAnalytics,
        private rapportService: RapportService,
        private alertCtrl: AlertController,
        private navCtrl: NavController,
        private menuCtrl: MenuController,
        private authService: AuthService,
        private lastScansService: LastScansService,
        private route: ActivatedRoute
    ) {
        this.platform.ready().then(() => {
            this.ga.trackView("Rapport");
        });
    }

    ngOnInit() {
        this.init();
    }

    async init(): Promise<any> {
        this.route.queryParams.subscribe(async params => {
            this.rapportId = params.rapportId !== undefined ? params.rapportId : 0

            if (this.rapportId === 0 || this.rapportId === null) {
                const liste = await this.lastScansService.getLastScans();
                const token = await this.authService.getToken();
                this.user = (token !== null) ? token.user : '';
                if (liste !== null) {
                    for(let i in liste) {
                        if (liste[i].user === this.user) {
                            this.rapport = (liste[i].scans !== null) ? liste[i].scans[0].data : { rapportId: 0 };
                            this.rapportId = this.rapport.rapportId;
                            this.loader = false;
                        }
                    }
                }
            } else {
                this.getRapport();
            }
        });
    }

    getRapport(): any {
        this.rapportService.getRapport(this.rapportId).subscribe({
            next: rapport => {
                this.rapport = rapport;
                this.loader = false;
            },
            error: async error => {
                console.error(error);
                if (error.status === 401 && !this.authService.refreshCalled) {
                    this.authService.refreshCalled = true;
                    const refreshToken = await this.authService.getRefreshToken();
                    if (refreshToken === null) {
                        await this.authService.removeToken();
                        await this.authService.removeRefreshToken();
                        this.menuCtrl.enable(true, 'unauthenticated');
                        this.menuCtrl.enable(false, 'authenticated');
                        this.navCtrl.navigateRoot('home');
                    } else {
                        this.authService.refresh(refreshToken).subscribe(async (data: any) => {
                            const token = await this.authService.getToken();
                            await this.authService.setToken(token.user, data.access_token, data.statut);
                            await this.authService.setRefreshToken(data.refresh_token)
                            const navigationExtras: NavigationExtras = {
                                queryParams: {
                                    rapportId: this.rapportId
                                }
                            };
                            this.navCtrl.navigateRoot('rapport', navigationExtras);
                        });
                    }
                } else {
                    this.authService.refreshCalled = false;
                    this.navCtrl.navigateRoot('home');
                }
            }
        });
    }

    goToListeObservations(id: number): any {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                rapportId: id
            }
        };
        this.navCtrl.navigateForward('liste-observations-rapport', navigationExtras);
    }

    async goToPdf(rapport: any): Promise<any> {
        if (rapport.pdfLimitExceeded) {
            const alert = await this.alertCtrl.create({
                header: 'Taille excédée',
                message: 'Ce rapport de ' + Math.round(rapport.pdfSize * 100)/100 + 'Mo ne peut pas être téléchargé car il dépasse la taille maximale autorisée de ' + rapport.pdlLimitSize + 'Mo. Nous vous invitons à le consulter sur l\'extranet Blue Trust Exploitation via votre navigateur.',
                buttons: ['D\'accord']
            });
            await alert.present();
        } else {
            this.platform.ready().then(_ => {
                this.ga.trackEvent("PDF", "click");
            });
            this.iab.create(encodeURI(rapport.pdfURL), '_system', 'location=yes');
        }
    }
}