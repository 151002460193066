import { Injectable, EventEmitter } from '@angular/core';
import { Storage } from '@ionic/storage';


@Injectable()
export class LastScansService {
    listeScans = new EventEmitter();
    
    constructor(private storage: Storage) {
        
    }

    emitListeScans(): Promise<any> {
        return this.getLastScans().then(
            liste => {
                let lastScans = (liste !== null) ? liste : [];
                this.listeScans.emit(lastScans);
            }
        );
    }
    
    getLastScans(): Promise<any> {
        return this.storage.get('last-scans');
    }

    async setLastScans(user, scan): Promise<any> {
        const liste = await this.getLastScans();
        let lastScans = (liste !== null) ? liste : [];

        if (lastScans.length > 0) {
            let addUser = true;
            for (let i in lastScans) {
                if (lastScans[i].user == user){
                    lastScans[i].scans.splice(0, 0, scan);
                    addUser = false;

                    if (lastScans[i].scans.length > 10) {
                        lastScans[i].scans.pop();
                    }
                }
            }
            if (addUser) {
                lastScans.push({
                    user: user,
                    scans: [scan]
                });
            }
        } else {
            lastScans.push({
                user: user,
                scans: [scan]
            });
        }
        this.storage.set('last-scans', lastScans);
        this.listeScans.emit(lastScans);
        return scan;
    }
}
