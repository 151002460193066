import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { HeaderPageModule } from "../header/header.module";
import { ElementVerifiePage } from "./element-verifie";
import { ElementVerifiePageRoutingModule } from "./element-verifie-routing.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule.forRoot(),
        ElementVerifiePageRoutingModule,
        HeaderPageModule
    ],
    declarations: [ElementVerifiePage]
})
export class ElementVerifiePageModule {}