import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { GoogleAnalytics } from '@awesome-cordova-plugins/google-analytics/ngx';
import { MenuController, NavController, Platform } from '@ionic/angular';
import { AuthService } from '../services/auth.service';
import { LastScansService } from '../services/lastScans.service';
import { ObservationsService } from '../services/observations.service';

@Component({
    selector: 'page-liste-observations-verifies',
    templateUrl: 'liste-observations-verifies.html'
})
export class ListeObservationsVerifiesPage implements OnInit {
    liste = <any>[];
    res = <any>{ paging: {} };
    scrollInfinite: boolean = true;
    loader: boolean = true;
    hasCritique: boolean = true;
    rapportId: number = 0;
    equipementId: number = 0;
    offset: number = 0;
    pageSize: number = 20;
    search: string = '';

    constructor(
    private platform: Platform,
     private ga: GoogleAnalytics,
     private navCtrl: NavController,
     private menuCtrl: MenuController,
     private route: ActivatedRoute,
     private observationsService: ObservationsService,
     private authService: AuthService,
     private lastScansService: LastScansService
    ) {
        this.platform.ready().then(_ => {
            this.ga.trackView("Liste observations vérifiés");
        });
    }

    ngOnInit(): any {
        this.init();
    }

    async init(): Promise<any> {
        this.route.queryParams.subscribe(async params => {
            this.rapportId = params.rapportId !== undefined ? params.rapportId : 0;
            this.equipementId = params.equipementId !== undefined ? params.equipementId : 0;

            const liste = await this.lastScansService.getLastScans();
            const token = await this.authService.getToken();
            if (liste !== null) {
                for (let i in liste) {
                    if (liste[i].user == token.user) {
                        this.hasCritique = (liste[i].scans[0].data.hasObservationCritique === true || liste[i].scans[0].data.nombreObservationsCritiques > 0);
                        break;
                    }
                }
            }
            this.getListe();
        });
    }

    getListe(): any {
        this.scrollInfinite = true;
        this.loader = true;
        this.observationsService.getListObservationsVerifies(this.rapportId, this.equipementId, this.offset, this.pageSize).subscribe({
            next: res => {
                this.res = res;
                this.liste = res.data;
                this.loader = false;
                if (res.paging.totalRecordCount <= this.offset + this.pageSize) {
                    this.scrollInfinite = false;
                }
            },
            error: error => {
                this.gestionErreur(error);
            }
        });
    }

    getSearch(search: string): any {
        if (search !== '') {
            this.scrollInfinite = true;
            this.loader = true;
            this.search = search;
            this.offset = 0;
            this.pageSize = 20;
            this.observationsService.getSearchObservationsVerifies(this.rapportId, this.equipementId, this.search, this.offset, this.pageSize).subscribe({
                next: res => {
                    this.res = res;
                    this.liste = res.data;
                    this.loader = false;
                    if (res.paging.totalRecordCount <= this.offset + this.pageSize) {
                        this.scrollInfinite = false;
                    }
                },
                error: error => {
                    this.gestionErreur(error);
                }
            });
        } else if (this.search !== '') {
            this.search = '';
            this.getListe();
        }
    }

    getObservation(item: any): any {
        const navigationExtras: NavigationExtras = {
            queryParams: { 
                listeObservations: JSON.stringify(this.liste),
                details: item
            }
        };
        this.navCtrl.navigateForward('details-observation', navigationExtras);
    }

    doInfinite(): Promise<any> {
        this.offset += this.res.paging.pageSize;
        this.pageSize = (this.res.paging.totalRecordCount > this.offset + this.res.paging.pageSize) ? this.res.paging.pageSize : this.res.paging.totalRecordCount - this.offset;
        return new Promise(
            (resolve: any) => {
                if (this.res.paging.totalRecordCount > this.offset) {
                    if (this.search !== '') {
                        this.observationsService.getSearchObservationsVerifies(this.rapportId, this.equipementId, this.search, this.offset, this.pageSize).subscribe({
                            next: res => {
                                this.res = res;
                                Array.prototype.push.apply(this.liste, res.data);
                                resolve();
                            },
                            error: error => {
                                this.gestionErreur(error);
                            }
                        });
                    } else {
                        this.observationsService.getListObservationsVerifies(this.rapportId, this.equipementId, this.offset, this.pageSize).subscribe({
                            next: res => {
                                this.res = res;
                                Array.prototype.push.apply(this.liste, res.data);
                                resolve();
                            },
                            error: error => {
                                this.gestionErreur(error);
                            }
                        });
                    }
                } else {
                    this.scrollInfinite = false;
                }
            }
        );
    }

    async gestionErreur(error: any): Promise<any> {
        console.error(error);
        if (this.authService.refreshCalled) {
            this.authService.refreshCalled = false;
            this.navCtrl.navigateRoot('home');
        } else if (error.status === 401) {
            this.authService.refreshCalled = true;
            const refreshToken = await this.authService.getRefreshToken();
            if (refreshToken == null) {
                await this.authService.removeToken();
                await this.authService.removeRefreshToken();
                this.menuCtrl.enable(true, 'unauthenticated');
                this.menuCtrl.enable(false, 'authenticated');
                this.navCtrl.navigateRoot('home');
            } else {
                this.authService.refresh(refreshToken).subscribe(async data => {
                    const token = await this.authService.getToken()
                    await this.authService.setToken(token.user, data.access_token, data.statut);
                    await this.authService.setRefreshToken(data.refresh_token);
                    const navigationExtras: NavigationExtras = {
                        queryParams: { 
                            rapportId: this.rapportId
                        }
                    };
                    this.navCtrl.navigateRoot('liste-observations-verifies', navigationExtras);
                });
            }
        }
    }
}