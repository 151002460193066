import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, from } from 'rxjs';
import { API } from '../config';

@Injectable()
export class RapportService {
    constructor(private api: API, private apiService: ApiService) {}

    getRapport(rapportId: number): Observable<any> {
        return from(this.apiService.get(this.api.URLS.rapportsURL +'/'+ rapportId));
    }

    getFiltres(rapportId: number, niveau: number): Observable<any> {
        return from(this.apiService.get(this.api.URLS.rapportsURL +'/'+ rapportId +'/Filtre/'+ niveau));
    }
}
