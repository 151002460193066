import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, from } from 'rxjs';
import { API } from '../config';

@Injectable()
export class QrCodeService {
    constructor(private api: API, private apiService: ApiService) {}

    getScanQRCode(url: string): Observable<any> {
        return from(this.apiService.get(this.api.URLS.scanQRCodeURL +'?id='+ encodeURIComponent(url), true));
    }
}
