import { Component, EventEmitter, Output } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { MenuController, NavController, Platform } from "@ionic/angular";
import { AuthService } from "../services/auth.service";
import { LastScansService } from "../services/lastScans.service";
import { GoogleAnalytics } from '@awesome-cordova-plugins/google-analytics/ngx';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { QrCodeService } from "../services/qrCode.service";
import { NavigationExtras } from "@angular/router";

@Component({
    selector: 'page-home',
    templateUrl: 'home.html'
})
export class HomePage {
    @Output() emitHasLastScans = new EventEmitter();
    private token: any = {
        user: '',
        token: '',
        statut: ''
    };
    isAuth: boolean = false;
    hasLastScans: boolean = false;
    loader: boolean = true;
    isIos: boolean = false;
    private isApp: boolean = true;

    constructor(
        private title: Title,
        private platform: Platform,
        private authService: AuthService,
        private lastScansService: LastScansService,
        private navCtrl: NavController,
        private ga: GoogleAnalytics,
        private barcodeScanner: BarcodeScanner,
        private qrCodeService: QrCodeService,
        private menuCtrl: MenuController
    ) {
        this.platform.ready().then(_ => {
            // On track la vue pour GA
            this.ga.trackView('Home Page');
            this.isApp = (this.platform.is('android') || this.platform.is('ios')) && !this.platform.is('mobileweb');
            this.isIos = this.platform.is('ios');
        });
    }

    ionViewDidEnter(): any {          
        if(!this.isApp) {
            this.title.setTitle('Blue Trust Exploitation, vérifiez la conformité de vos équipements');
        }
        this.init();
    }

    async init(): Promise<any> {
        this.token = await this.authService.getToken();
        this.isAuth = (this.token !== null && this.token.token !== '');
        const liste = await this.lastScansService.getLastScans();
        if (liste !== null && this.token !== null) {
            for (let i in liste) {
                if (liste[i].user === this.token.user) {
                    this.hasLastScans = true;
                }
            }
        }
        this.loader = false;
        this.authService.hasToken.subscribe((hasToken: boolean) => {                             
            if (!hasToken) {
                this.isAuth = false;
                this.token = {
                    user: '',
                    token: '',
                    statut: ''
                };
            }
        });
    }

    scan(): any {
        this.barcodeScanner.scan().then((barcodeData: any) => {
            if (!barcodeData.cancelled) {
                this.loader = true;
                this.platform.ready().then(_ => {
                    this.ga.trackEvent('Scan QRCode', 'scan');
                });
                // const barcodeData = { text: 'https://easycheck.socotec.fr?id=Y00003' };
                // const barcodeData = { text: 'https://easycheck.socotec.fr?id=6R0X9VZ' };
                // const barcodeData = { text: 'https://easycheck.socotec.fr?id=3N5W1I' };
                this.qrCodeService.getScanQRCode(barcodeData.text).subscribe({
                    next: async scan => {
                        this.token = await this.authService.getToken();
                        if (this.token !== null) {
                            // Si l'user a les droits pour lire le rapport 
                            if (scan.data.perimetre !== undefined) {
                                // On ajoute le scan à la liste de l'user
                                await this.lastScansService.setLastScans(this.token.user, scan);
                                this.platform.ready().then(_ => {
                                    if (barcodeData.text.match('socotec')) {
                                        this.ga.trackEvent('QRCode valide SOCOTEC', 'scan');
                                    } else
                                        this.ga.trackEvent('QRCode valide Non SOCOTEC', 'scan');
                                });
                                if (scan.qrCodeType == 'Installation') {
                                    this.goToPage('rapport');
                                } else {
                                    this.goToPage('element-verifie');
                                }
                            } else {
                                // On récupère le refresh token
                                const refresh = await this.authService.getRefreshToken();
                                // Si le refresh token existait
                                if (refresh !== null) {
                                    // On va récupérer un nouveau token
                                    this.authService.refresh(refresh).subscribe( async (newToken: any) => {
                                        await this.authService.setToken(this.token.user, newToken.access_token, newToken.statut);
                                        await this.authService.setRefreshToken(newToken.refresh_token);
                                        this.qrCodeService.getScanQRCode(barcodeData.text).subscribe( async (scan: any) => {
                                            await this.lastScansService.setLastScans(this.token.user, scan);
                                            this.loader = false;
                                            this.platform.ready().then(_ => {
                                                if (barcodeData.text.match('socotec')) {
                                                    this.ga.trackEvent('QRCode valide SOCOTEC', 'scan');
                                                } else {
                                                    this.ga.trackEvent('QRCode valide Non SOCOTEC', 'scan');
                                                }
                                            });
                                            if (scan.qrCodeType == 'Installation') {
                                                this.goToPage('rapport');
                                            } else {
                                                this.goToPage('element-verifie');
                                            }
                                        });
                                    });
                                } else {
                                    await this.authService.removeToken();
                                    await this.authService.removeRefreshToken();
                                    this.platform.ready().then(_ => {
                                        if (barcodeData.text.match('socotec')) {
                                            this.ga.trackEvent('QRCode valide SOCOTEC', 'scan');
                                        } else {
                                            this.ga.trackEvent('QRCode valide Non SOCOTEC', 'scan');
                                        }
                                    });
                                    this.menuCtrl.enable(true, 'unauthenticated');
                                    this.menuCtrl.enable(false, 'authenticated');
                                    this.loader = false;
                                    this.goToPage('rapport-sans-acces', scan);
                                }
                            }
                        } else {
                            this.platform.ready().then(_ => {
                                if (barcodeData.text.match('socotec')) {
                                    this.ga.trackEvent('QRCode valide SOCOTEC', 'scan');
                                } else {
                                    this.ga.trackEvent('QRCode valide Non SOCOTEC', 'scan');
                                }
                            });
                            this.loader = false;
                            this.goToPage('rapport-sans-acces', scan);
                        }
                    },
                    error: error => {
                        console.error(error);
                        this.loader = false;
                        this.platform.ready().then(_ => {
                            if (error.status == 403) {
                                this.ga.trackEvent('QRCode permission refusée', 'scan');
                            } else if(error.status == 404) {
                                this.ga.trackEvent('QRCode invalide', 'scan');
                            }
                        });
                    }
                });
            }
        }, (error: any) => {
            // An error occurred
            console.error('error', error);
        });
    }

    goToPage(page: string, data?: any): any {
        if (page == 'rapport-sans-acces') {
            const navigationExtras: NavigationExtras = {
                queryParams: {
                    data: JSON.stringify(data)
                }
            };
            this.navCtrl.navigateForward('rapport-sans-acces', navigationExtras);
        } else if (page == 'connexion') {
            this.navCtrl.navigateForward('connexion');
        } else if (page == 'savoir-plus') {
            this.navCtrl.navigateForward('savoir-plus');
        } else if (page == 'derniers-scans') {
            this.navCtrl.navigateForward('derniers-scans');
        } else if (page == 'rapport') {
            this.navCtrl.navigateForward('rapport');
        } else if (page == 'element-verifie') {
            this.navCtrl.navigateForward('element-verifie');
        }
    }

    callNumber(): any {
        window.location.href = 'tel: 0800802402';
    }
}