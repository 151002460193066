import { Component, OnInit } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { GoogleAnalytics } from '@awesome-cordova-plugins/google-analytics/ngx';
import { NavController, Platform } from '@ionic/angular';
import { AuthService } from '../services/auth.service';
import { LastScansService } from '../services/lastScans.service';

@Component({
  selector: 'page-derniers-scans',
  templateUrl: 'derniers-scans.html'
})
export class DerniersScansPage implements OnInit {
    liste = <any>[];
    loader: boolean = true;
    
    constructor(
        private platform: Platform,
        private ga: GoogleAnalytics,
        private lastScansService: LastScansService,
        private authService: AuthService,
        private navCtrl: NavController
    ) {
    }

    ngOnInit() {
        this.init();
    }

    async init(): Promise<any> {
        this.platform.ready().then(_ => {
            this.ga.trackView("Derniers scans");
        });
        const liste = await this.lastScansService.getLastScans();
        const token = await this.authService.getToken();
        if (liste !== null) {
            for(let i in liste) {
                if (liste[i].user === token.user) {
                    this.liste = liste[i].scans;
                }
            }
        }
        this.loader = false;
    }

    detailScan(item: any): any {
        if (item.qrCodeType == "Installation") {
            this.goToPage('rapport', { rapportId: item.data.rapportId });
        } else if (item.qrCodeType == "Equipement") {
            this.goToPage('element-verifie', { rapportId: item.data.rapportId, equipmentId: item.data.matR_ID });
        }
    }

    goToPage(page: any, data: any = {}): any {
        const navigationExtras: NavigationExtras = {
            queryParams: data
        };
        if (page === 'rapport') {
            this.navCtrl.navigateForward('rapport', navigationExtras);
        } else if (page === 'element-verifie') {
            this.navCtrl.navigateForward('element-verifie', navigationExtras);
        }
    }
}