import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { HeaderPageModule } from "../header/header.module";
import { SavoirPlusPage } from "./savoir-plus";
import { SavoirPlusPageRoutingModule } from "./savoir-plus-routing.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule.forRoot(),
        SavoirPlusPageRoutingModule,
        HeaderPageModule
    ],
    declarations: [SavoirPlusPage]
})
export class SavoirPlusPageModule {}