import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { HeaderPageModule } from "../header/header.module";
import { ConnexionPage } from "./connexion";
import { ConnexionPageRoutingModule } from "./connexion-routing.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule.forRoot(),
        ConnexionPageRoutingModule,
        HeaderPageModule
    ],
    declarations: [ConnexionPage]
})
export class ConnexionPageModule {}