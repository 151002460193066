import { Component, OnInit } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { MenuController, NavController, Platform } from '@ionic/angular';
import { AuthService } from './services/auth.service';
import { GoogleAnalytics } from '@awesome-cordova-plugins/google-analytics/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { LastScansService } from './services/lastScans.service';
import { QrCodeService } from './services/qrCode.service';
import { API } from './config';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html'
})

export class AppComponent implements OnInit {
    isApp: boolean;
    private user: string = '';

    constructor(
        private platform: Platform,
        private menuCtrl: MenuController,
        private statusBar: StatusBar,
        private authService: AuthService,
        private navCtrl: NavController,
        private ga: GoogleAnalytics,
        private lastScansService: LastScansService,
        private qrCodeService: QrCodeService,
        private api: API
    ) {
        this.platform.ready().then(async () => {
            this.isApp = (this.platform.is('android') || this.platform.is('ios')) && !this.platform.is('mobileweb');

            if (this.isApp) {
                setTimeout(() => {
                    (window.navigator as any).splashscreen.hide();
                }, 100);
            }

            this.statusBar.overlaysWebView(false);
            this.statusBar.backgroundColorByHexString('#000');

            if (!this.isApp) {
                // Google Analytics
                this.ga.startTrackerWithId("UA-103833510-3");
                var scanId = this.platform.getQueryParam('id');
                if (scanId != undefined) {
                    const token = await this.authService.getToken();
                    this.user = (token !== null) ? token.user : '';
                    const urlQRCode = this.api.qrCodeURL + '?id=' + scanId;
                    if (this.user != '') {
                        this.qrCodeService.getScanQRCode(urlQRCode).subscribe({
                            next: async scan => {
                                await this.lastScansService.setLastScans(this.user, scan);
                                if (urlQRCode.match("socotec")) {
                                    this.ga.trackEvent("QRCode valide SOCOTEC", "scan");
                                } else {
                                    this.ga.trackEvent("QRCode valide Non SOCOTEC", "scan");
                                }
                                if (scan.qrCodeType == "Installation") {
                                    this.goToPage({ page: 'rapport' });
                                } else {
                                    this.goToPage({ page: 'element-verifie' });
                                }
                            },
                            error: error => {
                                console.error(error);
                                if (error.status == 403) {
                                    this.ga.trackEvent("QRCode permission refusée", "scan");
                                } else if (error.status == 404) {
                                    this.ga.trackEvent("QRCode invalide", "scan");
                                }
                            }
                        });
                    } else {
                        this.qrCodeService.getScanQRCode(urlQRCode).subscribe({
                            next: scan => {
                                if (urlQRCode.match("socotec")) {
                                    this.ga.trackEvent("QRCode valide SOCOTEC", "scan");
                                }  else {
                                    this.ga.trackEvent("QRCode valide Non SOCOTEC", "scan");
                                }
                                this.goToPage({ page: 'rapport-sans-acces', data: scan });
                            },
                            error: error => {
                                console.error(error);
                                if (error.status == 403) {
                                    this.ga.trackEvent("QRCode permission refusée", "scan");
                                } else if (error.status == 404) {
                                    this.ga.trackEvent("QRCode invalide", "scan");
                                }
                            }
                        });
                    }
                }
            } else {
                // Google Analytics
                this.ga.startTrackerWithId("UA-103833510-1");
            }
        });
    }

    ngOnInit() {
        this.menuCtrl.enable(true, 'unauthenticated');
        this.menuCtrl.enable(false, 'authenticated');
        this.isAuthent();
    }

    async isAuthent() {
        const refreshToken = await this.authService.getRefreshToken();
        if(refreshToken != null) {
            const token = await this.authService.getToken();
            if (token != null) {
                this.menuCtrl.enable(false, 'unauthenticated');
                this.menuCtrl.enable(true, 'authenticated');
            }
        } else {
            await this.authService.removeToken();
        }
    }

    async goToPage(event) {
        let page = event.page;
        let data = event.data;
        // On redirige vers la bonne page
        if (page == 'rapport-sans-acces') {
            await this.authService.removeToken();
            await this.authService.removeRefreshToken();
            this.menuCtrl.enable(true, 'unauthenticated');
            this.menuCtrl.enable(false, 'authenticated');
            const navigationExtras: NavigationExtras = {
                queryParams: {
                    data: JSON.stringify(data)
                }
            };
            this.navCtrl.navigateRoot('rapport-sans-acces', navigationExtras);
        } else if (page == 'connexion') {
            this.navCtrl.navigateRoot('connexion', data);
        } else if (page == 'deconnexion') {
            await this.authService.removeToken();
            await this.authService.removeRefreshToken();
            this.menuCtrl.enable(true, 'unauthenticated');
            this.menuCtrl.enable(false, 'authenticated');
            this.navCtrl.navigateRoot('home');
        } else if (page == 'savoir-plus') {
            this.navCtrl.navigateForward('savoir-plus');
        } else if (page == 'derniers-scans') {
            this.navCtrl.navigateRoot('derniers-scans');
        } else if (page == 'liste-elements-verifies') {
            const navigationExtras: NavigationExtras = {
                queryParams: {
                    rapportId: data
                }
            };
            this.navCtrl.navigateRoot('liste-elements-verifies', navigationExtras);
        } else if (page == 'element-verifie') {
            if (data != null) {
                const navigationExtras: NavigationExtras = {
                    queryParams: {
                        equipmentId: data.equipmentId,
                        rapportId: data.rapportId
                    }
                };
                this.navCtrl.navigateRoot('element-verifie', navigationExtras);
            } else {
                this.navCtrl.navigateRoot('element-verifie');
            }
        } else if (page == 'rapport') {
            if (data != null) {
                const navigationExtras: NavigationExtras = {
                    queryParams: {
                        rapportId: data
                    }
                };
                this.navCtrl.navigateRoot('rapport', navigationExtras);
            } else {
                this.navCtrl.navigateRoot('rapport');
            }
        } 
        // On ferme le menu
        this.menuCtrl.close();
    }
}
