import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RecherchePage } from './recherche';
import { RecherchePageRoutingModule } from './recherche-routing.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule.forRoot(),
        RecherchePageRoutingModule
    ],
    declarations: [RecherchePage],
    exports: [RecherchePage]
})
export class RecherchePageModule {}
