import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { GoogleAnalytics } from '@awesome-cordova-plugins/google-analytics/ngx';
import { NavController, Platform } from '@ionic/angular';
import { Events } from '../services/events.service';

@Component({
  selector: 'page-rapport-sans-acces',
  templateUrl: 'rapport-sans-acces.html'
})
export class RapportSansAccesPage implements OnInit {
    lastScans: any;
    isIos: boolean = false;

    constructor(
        private platform: Platform,
        private ga: GoogleAnalytics,
        private navCtrl: NavController,
        private events: Events,
        private route: ActivatedRoute
    ) {
        this.platform.ready().then(() => {
            this.ga.trackView("Rapport sans accès");
            this.isIos = this.platform.is('ios');
        });
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.lastScans = params.data !== undefined ? JSON.parse(params.data).data : { qrCode: "" }
            this.events.publish('scanSansRapport:qrCode', this.lastScans.qrCode);
        });
    }

    goToPage(page: string): any {
        this.events.unsubscribe('scanSansRapport:qrCode');
        if (page === 'savoir-plus') {
            this.navCtrl.navigateForward('savoir-plus');
        } else if (page === 'connexion') {
            const navigationExtras: NavigationExtras = {
                queryParams: {
                    qrCode: this.lastScans.qrCode
                }
            };
            this.navCtrl.navigateForward('connexion', navigationExtras);
        }
    }

    callNumber(): any {
        window.location.href = "tel: 0800802402";
    }
}
