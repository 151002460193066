import { Injectable, EventEmitter } from '@angular/core';

import { ApiService } from './api.service';
import { API } from '../config';

import { Observable, from } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable()
export class AuthService {
    refreshCalled = false;
    hasToken = new EventEmitter();

    constructor(private api: API, private apiService: ApiService, private storageService: StorageService) {}

    setToken(user: string, token: string, statut: string): Promise<any> {
        return this.storageService.set('token', {user, token, statut});
    }

    getToken(): Promise<any> {
        return this.storageService.get('token');
    }

    removeToken(): any {
        this.storageService.remove('token');
        this.hasToken.emit(false);
    }

    setRefreshToken(token): Promise<any> {
        return this.storageService.set('refresh_token', token);
    }

    getRefreshToken(): Promise<any> {
        return this.storageService.get('refresh_token');
    }

    removeRefreshToken(): any {
        this.storageService.remove('refresh_token');
    }

    login(body: any): Observable<any> {
        return from(this.apiService.post(this.api.URLS.authentification, "username=" + body._username + "&password=" + body._password + "&AuthenticationMode=" + body._authenticationMode + "&grant_type=password"));
    }

    refresh(refreshToken): Observable<any> {
        return from(this.apiService.post(this.api.URLS.authentification, "refresh_token=" + refreshToken + "&grant_type=refresh_token"));
    }

    mdpOublie(body: any): Observable<any> {
        return from(this.apiService.get(this.api.URLS.mdpOublie + "?username=" + body._username));
    }
}
