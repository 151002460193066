import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { MomentModule } from "ngx-moment";
import { HeaderPageModule } from "../header/header.module";
import { RapportSansAccesPage } from "./rapport-sans-acces";
import { RapportSansAccesPageRoutingModule } from "./rapport-sans-acces-routing.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule.forRoot(),
        RapportSansAccesPageRoutingModule,
        HeaderPageModule,
        MomentModule
    ],
    declarations: [RapportSansAccesPage]
})
export class RapportSansAccesPageModule {}