import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SavoirPlusPage } from "./savoir-plus";

const routes: Routes = [
    { path: '', component: SavoirPlusPage }
];
  
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SavoirPlusPageRoutingModule {}