import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { GoogleAnalytics } from '@awesome-cordova-plugins/google-analytics/ngx';
import { NavController, Platform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { RapportService } from '../services/rapport.service';

@Component({
  selector: 'page-filtres',
  templateUrl: 'filtres.html'
})
export class FiltresPage implements OnInit {
    filters: any;
    hasFilterType: boolean;
    selectLevel1: Array<any> = [];
    selectLevel2: Array<any> = [];
    selectLevel3: Array<any> = [];
    private rapportId: number;
    private isObservation: boolean;

    constructor(
        private platform: Platform,
        private ga: GoogleAnalytics,
        private navCtrl: NavController,
        private rapportService: RapportService,
        private route: ActivatedRoute
    ) {}

    ngOnInit():any {
        this.route.queryParams.subscribe(params => {
            this.rapportId = params.rapportId !== undefined ? params.rapportId : 0;
            this.isObservation = params.isObservation !== undefined ? params.isObservation : false;
            this.hasFilterType = params.hasFilterType !== undefined ? params.hasFilterType : true;
            this.filters = params.filtres !== undefined ? JSON.parse(params.filtres) : {};

            this.getLevel(1).subscribe({
                next: level => {
                    this.selectLevel1 = level;
                }, error: error => {
                    console.error(error);
                }
            });
            this.getLevel(2).subscribe({
                next: level => {
                    this.selectLevel2 = level;
                },
                error: error => {
                    console.error(error);
                }
            });
            this.getLevel(3).subscribe({
                next: level => {
                    this.selectLevel3 = level;
                },
                error: error => {
                    console.error(error);
                }
            });
        });
    }

    getLevel(niveau: number): Observable<any> {
        return this.rapportService.getFiltres(this.rapportId, niveau);
    }

    applyFilters(): any {
        if (this.filters._level1 == undefined) {
            this.filters._level1 = '';
        }
        if (this.filters._level2 == undefined) {
            this.filters._level2 = '';
        }
        if (this.filters._level3 == undefined) {
            this.filters._level3 = '';
        }
        if (this.filters._observations == undefined) {
            this.filters._observations = '2';
        }
        this.platform.ready().then(_ => {
            this.ga.trackView("Filtres");
        });
        
        if (this.isObservation) {
            const navigationExtras: NavigationExtras = {
                queryParams: {
                    rapportId: this.rapportId,
                    filtres: JSON.stringify(this.filters)
                }
            };
            this.navCtrl.navigateBack('liste-observations-rapport', navigationExtras);
        } else {
            const navigationExtras: NavigationExtras = {
                queryParams: {
                    rapportId: this.rapportId,
                    filtres: JSON.stringify(this.filters)
                }
            };
            this.navCtrl.navigateBack('liste-elements-verifies', navigationExtras);
        }
    }

    goBack(): any {
        this.navCtrl.pop();
    }
}