import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, from } from 'rxjs';
import { API } from '../config';

@Injectable()
export class ElementsService {
    constructor(private api: API, private apiService: ApiService) {}

    getListElementsVerify(rapportId: number, offset: number, pageSize: number): Observable<any> {
        return from(this.apiService.get(this.api.URLS.elementsVerifiesURL +'/'+ rapportId +'/'+ offset +'/'+ pageSize +'/'));
    }

    getElementVerify(equipementId: number, rapportId: number): Observable<any> {
        return from(this.apiService.get(this.api.URLS.elementVerifieURL +'/?equipementId='+ equipementId +'&rapportId='+ rapportId));
    }

    getSearchListElementsVerify(rapportId: number, search: string, offset: number, pageSize: number): Observable<any> {
        return from(this.apiService.get(this.api.URLS.elementsVerifiesURL +'/'+ rapportId +'/Search/'+ search +'/'+ offset +'/'+ pageSize +'/'));
    }

    getFiltresElementsVerify(rapportId: number, offset: number, pageSize: number, niv1: string, niv2: string, niv3: string, istraite?: string): Observable<any> {
        return from(this.apiService.get(this.api.URLS.elementsVerifiesURL +'/'+ rapportId +'/Pagination/'+ offset +'/'+ pageSize +'/Filtrer?niveau1='+ niv1 +'&niveau2='+ niv2 +'&niveau3='+ niv3 +'&isTraite='+ istraite || '2'));
    }
}
