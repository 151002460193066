import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RecherchePageModule } from '../recherche/recherche.module';
import { HeaderPage } from './header';

import { HeaderPageRoutingModule } from './header-routing.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule.forRoot(),
        HeaderPageRoutingModule,
        RecherchePageModule
    ],
    declarations: [HeaderPage],
    exports: [HeaderPage]
})
export class HeaderPageModule {}
