import { Component } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { AuthService } from '../services/auth.service';
import { GoogleAnalytics } from '@awesome-cordova-plugins/google-analytics/ngx';

@Component({
  selector: 'page-mot-de-passe-oublie',
  templateUrl: 'mot-de-passe-oublie.html'
})
export class MotDePasseOubliePage {
    auth: any = {
        _username: '',
        _password: '',
        _souvenir: true
    };
    loader: boolean = false;
    
    constructor(
        private platform: Platform,
        private ga: GoogleAnalytics,
        private navCtrl: NavController,
        private authService: AuthService
    ) {
        this.platform.ready().then(_ => {
            this.ga.trackView("Mot de passe oublié");
        });
    }
    
    mdpOublie(): any {
        this.loader = true;
        this.authService.mdpOublie(this.auth).subscribe({
            next: () => {
                this.navCtrl.pop();
            },
            error: error => {
                console.error(error);
                this.loader = false;
            }
        });
    }
}
