import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


import { ToastService } from './toast.service';

import { firstValueFrom, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { StorageService } from './storage.service';

@Injectable()
export class ApiService {
    isQrcode = false;
    constructor(private http: HttpClient, private toastService: ToastService, private storageService: StorageService) {
        this.http = http;
    }

    async get(url: string, isQrCode?: boolean): Promise<any> {
        this.isQrcode = isQrCode || false;
        const headers = await this.generateHeadersWithToken();
        return firstValueFrom(this.http.get(url, headers).pipe(
            map(res => this.extractData(res)),
            catchError(this.handleError.bind(this))));
    }

    async post(url: string, params: string, isJson?: boolean): Promise<any> {
        isJson = isJson || false;
        const headers = await this.generateHeadersWithToken(!isJson);
        return firstValueFrom(this.http.post(url, isJson ? JSON.parse(params) : params, headers).pipe(
            map(res => this.extractData(res)),
            catchError(this.handleError.bind(this))));
    }

    private async generateHeadersWithToken(isPost?: boolean): Promise<any> {
        isPost = isPost || false;
        const token = await this.storageService.get('token');
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': isPost ? 'application/x-www-form-urlencoded' : 'application/json',
                'Authorization': (token !== null) ? 'Bearer ' + token.token : ''
            })
        };
        return headers;
    }

    private extractData(res: any): any {
        console.log("isQrCode", this)
        this.isQrcode = false;
        console.log(res);
        return res || {};
    }

    private handleError(error: any): any {
        if (error.status == 400) {
            if (this.toastService) {
                this.toastService.setToast('Votre identifiant et/ou votre mot de passe sont incorrects');
            }
        } else if (error.status === 403) {
            if (this.toastService) {
                this.toastService.setToast('Vous n\'êtes pas autorisés à consulter ceci.');
            }
        }  else if (error.status === 404 && this.isQrcode) {
            if (this.toastService) {
                this.toastService.setToast('QR-Code invalide.');
            }
        } else if (error.status == 500) {
            if (this.toastService) {
                this.toastService.setToast('Une erreur serveur est survenue. Merci de réessayer');
            }
        } else if (error.status !== 401) {
            if (this.toastService) {
                this.toastService.setToast(error.status + ' - ' + error.statusText);
            }
        }
        this.isQrcode = false;
        return throwError(() => error);
    }
}
