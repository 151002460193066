import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { HeaderPageModule } from "../header/header.module";
import { RapportPage } from "./rapport";
import { RapportPageRoutingModule } from "./rapport-routing.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule.forRoot(),
        RapportPageRoutingModule,
        HeaderPageModule
    ],
    declarations: [RapportPage]
})
export class RapportPageModule {}